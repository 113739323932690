<template>
  <button :class="$style.root">
    <slot />
  </button>
</template>

<script lang="ts" setup>
</script>

<style module>
.root {
  composes: reset-button button-disabled-state from global;

  width: calc(var(--font-x-large--font-size) * 1);
  height: calc(var(--font-x-large--font-size) * 1);
  /* border-radius: 50%; */

  position: relative;
  background-color: var(--color--background);
  color: var(--color--primary);
}

.root[dir='next'] {
  transform: rotateZ(180deg);
}

.root:hover {
  background-color: var(--color--primary);
  color: var(--color--background);
}
</style>
